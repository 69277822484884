.flipbook-viewport[data-v-e26282da] {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  packground-position: center center;
  width: 100vw;
  height: 100vh;
}
.flipbook-viewport .flipbook-container[data-v-e26282da] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.flipbook-viewport .flipbook[data-v-e26282da] {
  overflow: hidden;
  background: #fff;
  -webkit-box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
}
.flipbook .page[data-v-e26282da] {
  background: #fff;
  -webkit-box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
}
.flipbook-viewport .shadow[data-v-e26282da] {
  -webkit-transition: -webkit-box-shadow 0.5s;
  -moz-transition: -moz-box-shadow 0.5s;
  -o-transition: -webkit-box-shadow 0.5s;
  -ms-transition: -ms-box-shadow 0.5s;
  -webkit-box-shadow: 0 0 0.2rem #ccc;
  -o-box-shadow: 0 0 0.2rem #ccc;
  -ms-box-shadow: 0 0 0.2rem #ccc;
  box-shadow: 0 0 0.2rem #ccc;
}
.flipbook-btn[data-v-e26282da] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 0.4rem;
}
.flipbook-btn > div[data-v-e26282da] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  line-height: 1.5;
  background: #fff;
  border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
  padding: 0.04rem 0.2rem;
  min-width: 0.2rem;
  color: #008f93;
  opacity: 0.8;
}
.flipbook-btn .flipbook-page[data-v-e26282da] {
  font-weight: bold;
  font-size: 0.24rem;
}
.flipbook-btn .flipbook-next[data-v-e26282da],
.flipbook-btn .flipbook-prev[data-v-e26282da] {
  cursor: pointer;
}
.flipbook-btn .returnBtn[data-v-e26282da] {
  margin: 0.3rem auto;
  color: #fff;
  background: var(--themeColor);
  opacity: 1;
  padding: 0.08rem 0.16rem;
  font-size: 0.18rem;
}